<template>
  <div class="d-flex">
    <router-link v-if="backTo" :to="backTo" class="text-decoration-none font-lg place-y-center d-block " style="z-index: 9"><div>&lt;</div>  </router-link>
    <h2 class="f-1 font-md text-gray-75 bg-white p-2 m-x-5" style="text-align: center">
      {{Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format($store.getters.totalPrice)}}</h2>
  </div>
  <div class="progress-bar position-relative"><div class="filled position-absolute" :style="progressBarStyle"></div></div>
  <p v-if="!hideSteps" class="text-primary font-strong text-uppercase p-b-0">step {{currentStep}} of {{steps}}</p>
</template>

<script>
export default {
  name: "selectionHeader",
  props: {
    backTo: String,
    steps: Number,
    currentStep: Number,
    hideSteps: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    progressBarStyle(){
      return {width:this.currentStep/this.steps * 100 +'%'};
    }
  }
}
</script>

<style scoped>
 .progress-bar{
   height: 1px;
   background: rgba(77, 77, 77, 0.2);
   border-radius: 5px;
 }
 .progress-bar .filled{
   height: 3px;
   top: -1px;
   background: rgb(77, 77, 77);
   border-radius: 5px;
 }
</style>