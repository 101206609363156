<template>
  <section>
    <div class="grid m-t-5 md:grid-9-3 md:m-t-0 ">
      <div class=" h-100vh position-relative">
        <div
            style="background-size: cover; background-position: center center; overflow: hidden; background-repeat: no-repeat; position: absolute; z-index:1; top:0; "
            :style="{backgroundImage:'url('+img+')'}" class="w-100p h-100p position-relative">

        </div>


      </div>
      <div class="m-l-2 p-3 d-flex h-100vh scroller" style="flex-direction: column; overflow-y: auto">
        <selection-header :back-to="'/add-ons'" :steps="6" :current-step="6" :hide-steps="true"/>
        <h2 class="font-lg">Register to save your configuration</h2>

        <p>
          Are you ready for your dream-boat? Let us get back to you about <strong>your unique</strong> configuration.
        </p>
        <div class="p-5 w-80p font-lg bg-white text-primary b-1 b-primary" style="top: 20vh; left: 5%; z-index: 2"
             v-if="showDone">
          Thank you! <br>
          Please check your email
        </div>
        <div v-if="!allowed" class="bg-warning text-white p-3">
          <h3>Warning</h3>
          <p>Your configuration is not processable as you rejected necessary privacy settings.</p>
          <button class="primary-button" @click="$store.commit('setGdpr', true)">edit privacy</button>
          <button class="action-button m-l-2" @click="checkPrivacy">check again</button>
        </div>
        <form @submit.prevent="send" class="f-1 d-flex" style="flex-direction: column" v-if="!showDone&&allowed">
          <div class="f-1 ">
            <div>
              <label>
                <input type="radio" value="person" v-model="contact.kind">
                Private
              </label>
              <label>
                <input type="radio" value="company" v-model="contact.kind">
                Company
              </label>
            </div>
            <div class="grid">
              <div class="m-b-5 grid" v-if="contact.kind === 'company'">
                <input v-model="contact.companyName" name="company" type="text"
                       class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="Company name"
                       required>
              </div>

              <input v-model="contact.firstName" name="name" type="text"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="First name" required>
              <input v-model="contact.lastName" name="name" type="text"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="Last name" required>
            </div>
            <div class="grid">
              <input v-model="contact.street" name="street" type="text"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="Street address"
                     required>
            </div>
            <div class="grid-6-6">
              <input v-model="contact.zip" name="zip" type="text"
                     :pattern="contact.country==='DE'?'[0-9]{5}':'[0-9A-Z\s-]{4,9}'"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1 m-r-3" placeholder="Postal code"
                     required>
              <input v-model="contact.place" name="place" type="text"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="Place" required>
            </div>
            <div class="d-flex">

              <div class="f-1">
                <select v-model="contact.country" name="country"
                        class="p-x-2 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1 w-100p" required>
                  <option :value="null" disabled>Country</option>
                  <option v-for="country in countries" :value="country.code">{{ country.name }}</option>
                </select>
              </div>
            </div>
            <div class="grid m-t-5">
              <input v-model="contact.email" name="email" type="email"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="Email" required>
              <input v-model="contact.phone" name="phone" type="text" pattern="\+*[0-9\s-]{4,16}"
                     class="p-x-3 p-y-2 bg-white-75 b-rounded b-1 text-black m-y-1" placeholder="Mobile number"
                     required>
            </div>
            <div class="m-t-5">
              <label>
                <input type="checkbox" required>
                I agree to the
                <router-link to="/legal/privacy" class="text-primary text-decoration-none">privacy policy</router-link>
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" required>
                I agree to the
                <router-link to="/legal/tac" class="text-primary text-decoration-none">terms and conditions
                </router-link>
              </label>
            </div>
          </div>
          <div class="spacer f-1"></div>
          <div class="grid-4-8 m-b-3 w-95p m-t-2">
            <div>
              <router-link to="/add-ons" class="grid h-100p text-decoration-none">
                <button class="action-button m-r-1">back</button>
              </router-link>
            </div>
            <button type="submit" class="primary-button place-y-end">save</button>
          </div>


        </form>
      </div>
    </div>

  </section>
</template>

<script>
import api from "@/api";
import axios from "axios";
import img from '@/assets/add-ons.jpg';
import selectionHeader from "@/components/selectionHeader";
import countries from "@/assets/countries";

export default {
  name: "Checkout",
  components: {selectionHeader},
  data: () => ({
    contact: {
      country: 'DE',
      kind: 'person'
    },
    countries,
    showDone: false,
    allowed: false,
    img
  }),
  async mounted() {
    this.checkPrivacy();

   /* this.$refs.contact.innerText = 'contact' + this.$refs.contact.innerText
        .replace(/313/m,'ubi')
        .replace('.io','.boats')*/
  },
  methods: {
    checkPrivacy(){
      if(localStorage.gdpr){
        const parsed = JSON.parse(localStorage.gdpr);
        this.allowed = parsed.orgaMax && parsed.mailjet;
      }
    },

    send(ev) {
      api.post('/thank-you', {
        contact: this.contact,
        store: {
          modules: this.$store.state.selectedModules,
          boatModel: this.$store.state.boatModel,
          exterior: this.$store.state.exterior,
          interior: this.$store.state.interior,
          electricity: this.$store.state.electricity,
          battery: this.$store.state.battery,
          water: this.$store.state.water,
          waste: this.$store.state.waste,
          addOns: this.$store.state.addOns
        }
      }).finally(() => this.showDone = true)
    }
  }
}
</script>

<style scoped>

</style>